import { FC, MouseEvent } from 'react';
import classNames from 'classnames';
import { useSwiperSlide } from 'swiper/react';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { useViewport } from 'context/viewport';
import { intercomShowNewMessage } from 'lib/intercom';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/ModelCard.module.scss';
import { Model } from 'types';
import { formatPrice } from 'utils/text-helpers';

export const ModelCardTestIds = {
  container: 'model-card-container'
};

export interface ModelCardProps {
  model: Model;
  theme?: 'default' | 'primary' | 'adjustable';
  imageSize?: string;
  className?: string;
  priorityLoadImage?: boolean;
}

export const ModelCard: FC<ModelCardProps> = ({
  model,
  imageSize = '100vw',
  className,
  theme = 'default',
  priorityLoadImage = false
}) => {
  const hasListings = model.lowestPrice !== 0;
  const modelLink = pageRoutes.brandModel({
    brandDisplayName: model.brand.displayName,
    modelDisplayName: model.displayName,
    referenceNumber: model.referenceNumber,
    modelId: model.id
  });
  const { isMobile } = useViewport();
  const swiperSlide = useSwiperSlide();

  const showCard = swiperSlide == null || swiperSlide.isVisible || isMobile;
  const onInquireClick = (event: MouseEvent<HTMLElement>) => {
    if (!hasListings) {
      event.stopPropagation();
      intercomShowNewMessage();
    }
  };

  const title = `${model?.brand?.name ? model?.brand?.name + ' ' : ''}${model?.name ? model?.name + ' ' : ''}${
    model?.referenceNumber ? model?.referenceNumber + ' ' : ''
  }`;

  const commonSection = (
    <>
      <CloudinaryImage
        responsive
        alt={`${title} Model Image`}
        sizes={imageSize}
        src={model.images.length > 0 ? model.images[0].url : 'model_placeholder.png'}
        priority={priorityLoadImage}
      />
      <div className='d-flex flex-column justify-content-end h-100'>
        <div className={classNames('text-secondary riforma-medium fs-10px letter-spacing-1', styles.title)}>
          {model.brand.name.toUpperCase()}
        </div>
        <div
          style={{ minHeight: '32px' }}
          className={classNames('text-primary riforma-regular mb-2 fs-12px w-100', styles.subtitle)}
        >
          {model.name} {model.referenceNumber}
        </div>
      </div>
    </>
  );

  return (
    <div
      className={classNames(
        'px-2 d-flex flex-column rounded-2 text-center',
        className,
        theme && styles[`theme-${theme}`],
        showCard ? 'opacity-1' : 'opacity-0'
      )}
      data-testid={ModelCardTestIds.container}
    >
      {hasListings ? (
        <Link href={modelLink} className='d-flex flex-column h-100'>
          {commonSection}
          <div className='mt-auto rounded-1 mb-2 bg-lightSuccess'>
            <div className='fs-12px pt-2 riforma-medium text-success'>{model.count} available</div>
            <div className='fs-12px pb-2 text-success riforma-regular'>From {formatPrice(model.lowestPrice || 0)}</div>
          </div>
        </Link>
      ) : (
        <>
          <Link href={modelLink} className='d-flex flex-column h-100'>
            {commonSection}
          </Link>
          <div
            role='button'
            onClick={onInquireClick}
            className={classNames(styles.inquire, 'rounded-1 mb-2 bg-lightPrimaryBackground')}
          >
            <div className='fs-12px pt-2 riforma-medium text-primary'>Inquire to source</div>
            <div className='fs-12px pb-2 text-lightPrimaryText riforma-regular'>Bezel Concierge</div>
          </div>
        </>
      )}
    </div>
  );
};
