import { FC } from 'react';
import classNames from 'classnames';

import { COLORS } from 'consts';
import { useBranding } from 'context/branding';
import { useModal } from 'context/modal/ModalContext';
import { Listing } from 'types';

import { ListingTagInfoModal } from './ListingTagInfoModal';

export type ListingTagProps = {
  tags: Listing['tags'];
  className?: string;
  color?: 'gray' | 'green';
};

export const ListingTag: FC<ListingTagProps> = ({ tags, className, color = 'green' }) => {
  const { openModal, closeModal } = useModal();
  const { useSellerBranding } = useBranding();

  // disable tags on seller sites
  if (useSellerBranding) {
    return;
  }

  let text = '';
  let description = '';
  let bg = '';

  tags.forEach(tag => {
    // if both BEST_PRICE and GREAT_PRICE keys present BEST_PRICE should be displayed
    if (text === 'Best price') {
      return;
    }

    if (tag.stableKey === 'BEST_PRICE') {
      bg = 'linear-gradient(269deg, #07745B 0%, #076D74 100%)';
      text = 'Best price';
      description =
        'This watch is under the market value for this specific model and the best priced example on Bezel currently';
    } else if (tag.stableKey === 'GREAT_PRICE') {
      text = 'Great price';
      description =
        'This watch is under the market value for this specific model and considered by our team to be a great price';
      bg = color === 'gray' ? 'rgba(0,0,0,0.4)' : COLORS.primary;
    }
  });

  if (!text) {
    return null;
  }

  const openInfoModel = () =>
    openModal(
      <ListingTagInfoModal title={text} closeModal={closeModal}>
        {description}
      </ListingTagInfoModal>
    );

  return (
    <div
      role='button'
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        openInfoModel();
      }}
      className={classNames(
        'position-absolute px-3 py-2 m-3 riforma-regular fs-12px text-white border-radius-46',
        className
      )}
      style={{
        background: bg,
        zIndex: 5
      }}
    >
      {text}
    </div>
  );
};
