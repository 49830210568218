import { FC, PropsWithChildren } from 'react';
import { OffcanvasProps } from 'react-bootstrap';

import { Slideout } from 'components/design-system/Slideout';
import { intercomShowNewMessage } from 'lib/intercom';

export const ListingTagInfoModalTestIds = {
  container: 'listing-tag-info-modal-container',
  button: 'listing-tag-info-modal-button'
};

export type ListingTagInfoModalProps = {
  title: string;
} & OffcanvasProps &
  PropsWithChildren;

export const ListingTagInfoModal: FC<ListingTagInfoModalProps> = ({ isOpen, closeModal, children, title }) => (
  <Slideout
    title={title}
    data-testid={ListingTagInfoModalTestIds.container}
    submit={{
      onClick: intercomShowNewMessage,
      testId: ListingTagInfoModalTestIds.button,
      title: 'Talk to a Concierge about it',
      className: 'fs-14px fs-lg-16px'
    }}
    isOpen={isOpen}
    closeModal={closeModal}
  >
    <div className='h-100 d-flex align-items-center justify-content-center text-center text-primary riforma-regular fs-16px opacity-60 px-4'>
      {children}
    </div>
  </Slideout>
);
